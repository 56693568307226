.google-login-button {
  margin: "10px 0 20px 0";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 36px;
  background-color: #fff;
  cursor: pointer;
}

.google-login-button > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  background: #fff;
  padding: 15px 0;
  border-radius: 8px !important;
  border: 1px solid #E5E5E5;
  margin-right: 8px;
  box-shadow:  0px 4px 6px rgba(0, 0, 0, 0.1);
}

.google-login-button span {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
  white-space: nowrap;
  margin: 6px 0;
}

.google-login-button img {
  margin-right: 0;
  width: 18px;
  height: 18px;
  object-fit: contain;
}
