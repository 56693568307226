

.MuiDialog-paper {
  border-radius: 10px !important;
}

.MuiInputLabel-outlined {
 font-family: 'Inter' !important; 
 font-weight: 300 !important;
}  

.MuiTypography-h6 {
  font-family: 'Inter' !important;
  font-size: 0.8rem !important;
}
.MuiTypography-h3 {
  font-family: 'Inter' !important;
  font-size: 0.8rem !important;
}
.MuiTypography-body1 {
  font-size: 0.8rem;
}

.makeStyles-root-50 {
  margin-bottom: 70px !important;
}

@media (max-width: 1450px) {
  .makeStyles-root-54 {
    width: 90% !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 800px) {
  .css-djwaig {
    margin-top: 40px !important;
  }
}
.makeStyles-root-54 {
  margin-bottom: 10px !important;
  margin-top: 30px !important;
}

.css-16b5y55-MuiPickersLayout-contentWrapper {
  width: 'auto' !important;
}

.react-calendar {
  margin: auto !important;
}

@media (max-width: 767px) {
  .MuiCard-root {
    margin: auto !important;
    margin-bottom: 20px !important;
  }
}

.MuiPickersStaticWrapper-staticWrapperRoot {
  background-color: white !important;
}

.MuiPickersCalendarHeader-dayLabel {
  font-family: 'Inter' !important;
  font-weight: 600 !important;
}
.MuiTypography-alignCenter {
  font-family: 'Inter' !important;
}
.MuiTypography-body2 {
  font-family: 'Inter' !important;
}

.MuiPickersBasePicker-pickerView {
  margin-bottom: 10px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.MuiIconButton-root {
  padding: 5px !important;
}

.MuiPickersCalendarHeader-switchHeader {
  margin-bottom: 20px !important;
}

.MuiPickersCalendarHeader-dayLabel {
  color: #669b9b !important;
}

.MuiPickersDay-current {
  border: 1px solid grey !important;
  color: #a9a9a9 !important;
}

.makeStyles-formControl-74 {
  max-width: 340px !important;
}

.MuiPickersCalendarHeader-transitionContainer {
  height: 27px !important;
}

.MuiFormHelperText-root.Mui-error {
  font-family: 'Inter' !important;
} 
.MuiFormLabel-root {  
  font-family: Inter !important;

} 
.MuiFilledInput-input {
  padding: 27px 12px 6px !important
} 

.MuiOutlinedInput-input {
  font-family: Inter !important; 
  font-weight: 400 !important;

}